$primary-blue: #000041;
$primary-green: #33ce60;
$secondary-green: #00bf00;
$third-green: #0e4700;

.contentWrapper {
  padding: 32px;
  &:before {
    content: "";
    position: absolute;
    top: 32px;
    left: 32px;
    border-radius: 20px 10px 0px 0px;
    width: calc(100% - 64px);
    height: 20%;
    background-color: $primary-green;
    background-size: cover;
    z-index: -1;
    animation: 2s appear ease-in-out;
  }
}

.dashboardWrapper {
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

@keyframes appear {
  0% {
    width: 0;
  }
  20% {
    width: 0;
  }
  100% {
    width: calc(100% - 64px);
  }
}
