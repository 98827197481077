$primary-blue: #000041;
$primary-green: #33ce60;
$secondary-green: #00bf00;
$third-green: #0e4700;

.breadcrumbItem {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  padding: 8px;
  width: 32px;
  height: 32px;
  font-size: 24px;
  background-color: $primary-green;
  border-radius: 50%;
  aspect-ratio: 1 / 1;
  transition: background-color 0.3s, border-radius 0.2s, width 0.3s;
  color: white;
  overflow: hidden;
  p {
    font-size: 0px;
  }
  cursor: pointer;
}

.breadcrumbItemSelected {
  display: flex;
  align-items: center;
  flex-direction: row;
  padding: 8px;
  height: 32px;
  font-size: 24px;
  background-color: $primary-green;
  border-radius: 60px;
  transition: background-color 0.3s, border-radius 0.05s, width 0.3s;
  cursor: pointer;
  color: white;
}
