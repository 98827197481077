.iconSelectorWrapper {
  background-color: #dce6f6;
  padding: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  font-size: 24px;
  transition: 0.5s ease;
  p {
    font-size: 18px;
    font-weight: 500;
    font-family: 'Poppins';
  }
  &:hover {
    cursor: pointer;
    background-color: #bfd4f5;
  }
}

.selectorIconWrapper {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  border-radius: 10px;
  padding: 8px;
  background-color: #f6f6fa;
  font-size: 24px;
}

.iconSelectorWrapper.selected {
  background-color: #1756c5;
  color: white;
}
