.headerItem {
  padding: 8px 16px;
  border-radius: 10px;
  width: 100px;
  font-family: "Poppins";
  font-weight: 500;
  font-size: 18px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: 2;
  background-color: white;
  &:hover {
    background-color: #f4f5fa;
    transition: 1s;
  }
  overflow: hidden;
}

.selected {
  color: white;
  &:before {
    content: "";
    width: 100%;
    height: 102%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #f4f5fa;
    background-color: #007bff;
    z-index: -1;
    transition: 1s;
  }
}

.unselected {
  color: black;
  &:before {
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    top: -52%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #f4f5fa;
    background-color: #007bff;
    z-index: -1;
    transition: 1s;
  }
}

.menuWrapper {
  background-color: white;
}
