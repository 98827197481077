.dashboardLayoutWrapper {
  margin: 32px;
  width: calc(100vw - 64px);
  height: calc(100vh - 64px);
  display: flex;
  align-items: center;
  justify-content: center;
  background-position: center center;
}

.mobileLayoutWrapper {
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
