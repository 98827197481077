$primary-blue: #000041;
$primary-green: #33ce60;
$secondary-green: #00bf00;
$third-green: #0e4700;

.iconButtonWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  aspect-ratio: 1 / 1;
  height: 80%;
  cursor: pointer;
  border-radius: 50%;
  transition: all 0.3s;
  color: white;
  background-color: $primary-blue;
  font-size: 40px;
  &:hover {
    background-color: rgba(0, 0, 0, 0.1);
  }
}
