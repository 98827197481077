$primary-blue: #000041;
$primary-green: #33ce60;
$secondary-green: #00bf00;
$third-green: #0e4700;

.counterPartWrapper {
  padding: 12px 8px;
  border: 1px solid black;
  border-radius: 10px;
}

.addButton {
  padding: 12px 8px;
  border: 1px dashed black;
  border-radius: 10px;
  cursor: pointer;
  transition: 0.5s ease;
  &:hover {
    background-color: #e8e8f2;
  }
}
.icon {
  font-size: 24px;
  cursor: pointer;
}

.remove {
  font-size: 24px;
  cursor: pointer;
  transition: 0.5s;
  &:hover {
    color: red;
  }
}

.aceptmentWrapper {
  padding: 16px;
  border: 1px solid black;
  border-radius: 10px;
  position: relative;
}

.aceptmentButton {
  width: 64px;
  height: 64px;
  border-radius: 20px;
  background-color: #e8e8f2;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: 16px;
  bottom: 16px;
  font-size: 32px;
  color: $primary-green;
}

.aceptmentIcon {
  animation: appear 0.2s ease;
}

@keyframes appear {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}

.categoriesWrapper {
  width: calc(100% - 32px);
  background-color: #f3f3f7;
  padding: 16px;
  border-radius: 10px;
  overflow: hidden;
}

.categorieWrapper {
  width: 300px;
  height: 150px;
  background-color: white;
  padding: 16px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  cursor: pointer;
  transition: 0.5s ease;
  border: 1px solid white;
  &:hover {
    background-color: #f3f3f7;
  }
}

.selectedCategorie {
  color: black;
  background-color: #f3f3f7 !important;
  border-radius: 10px;
  border: 1px solid #2015fe;
  transition: 0.5s ease;
}

.categoriesTitle {
  font-size: 24px;
  font-weight: 600;
}

.addIcon {
  color: #2015fe;
  font-size: 28px;
}

.addText {
  color: #2015fe;
  font-size: 16px;
  font-weight: 600;
}

.categorieBox {
  width: 20px;
  height: 20px;
  background-color: $primary-green;
  border-radius: 5px;
}

.categorieText {
  font-weight: 600;
}

.secondaryText {
  font-size: 16px;
  font-weight: 500;
  color: #b1b1b1;
}

.expand {
  color: #2015fe;
  font-size: 14px;
  font-weight: 700;
  padding: 8px;
}

.partnershipPreviewWrapper {
  width: 33%;
  max-width: 400px;
  min-width: 300px;
  height: 250px;
  border: 1px solid rgba(99, 99, 99, 0.2);
  border-radius: 20px;
  padding: 16px;
}

.statusWrapper {
  padding: 8px 12px;
  border: 1px solid rgba(99, 99, 99, 0.3);
  border-radius: 10px;
  font-size: 18px;
  font-weight: 600;
  height: fit-content;
  background-color: #f3e9fe;
  color: #7b18f9;
}

.fileWrapper {
  padding: 24px;
  border-radius: 10px;
  background-color: #f3f4f6;
  font-size: 20px;
  color: #6b7280;
}

.partnershipPreviewTitle {
  font-size: 24px;
  font-weight: 600;
}

.partnershipPreviewSubTitle {
  font-size: 16px;
  font-weight: 500;
  color: #b1b1b1;
}

.download {
  font-size: 20px;
  font-weight: 500;
  color: #b1b1b1;
}

.statusItem {
  cursor: pointer;
  .statusIcon {
    color: #acafb8;
    font-size: 10px !important;
  }
  .statusText {
    font-size: 18px;
    color: #8c919d;
    font-weight: 500;
  }
}

.selected {
  position: relative;
  &:before {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    margin-top: 15px;
    width: 100%;
    padding: 0px 8px;
    content: '';
    height: 100%;
    border-bottom: 2px solid #aeb3b9;
    animation: 0.5s appear ease;
  }
}

.statusBarWrapper {
  border-bottom: 1px #eaecef solid;
  margin: 16px;
  padding: 8px;
}

.statusItem {
  cursor: pointer;
  .statusIconSelected {
    color: $primary-green;
    font-size: 10px !important;
  }
  .statusTextSelected {
    font-size: 18px;
    color: black;
    font-weight: 500;
  }
}

@keyframes appear {
  0% {
    width: 0;
  }
  100% {
    width: 100%;
  }
}

.selectedColor {
  position: relative;

  &:before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    top: 50%;
    left: 50%;
    padding: 2px;
    transform: translate(-50%, -50%);
    border-radius: 50%;
    animation: rotateAnimation 0.5s ease forwards;
  }
}

@keyframes rotateAnimation {
  0% {
    transform: translate(-50%, -50%) rotate(0deg);
  }
  50% {
    transform: translate(-50%, -50%) rotate(360deg);
    border: 3px solid transparent;
  }
  100% {
    border: 3px solid black;
  }
}
