.inputSearch {
  z-index: 2;
  height: 71px;
  border: none;
  border-radius: 10px;
  font-family: "Poppins", sans-serif;
  font-size: 24px;
  font-weight: bold;
  padding: 0px 16px;
  width: 100%;
  background-color: transparent;
  &:active,
  &:focus {
    border: none;
    outline: none;
  }
}

.searchBarWrapper {
  background-color: white;
  position: relative;
  border-radius: 10px;
  z-index: 2;
}

.dropDownFilters {
  width: 100%;
  height: 80px;
  z-index: 1;
  position: absolute;
  top: 140%;
  background-color: #e5e5e5;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 0px 0px 10px 10px;
  animation: 1s filtersAppear;
  transform-origin: top center;
  overflow: hidden;
}

@keyframes filtersAppear {
  0% {
    height: 0%;
    top: 50%;
  }
  100% {
    height: 100%;
    top: 140%;
  }
}

.filterButton {
  border: black 1px solid;
  display: flex;
  align-items: center;
  justify-content: center;
  color: black;
  background-color: #e5e5e5;
  border-radius: 5px;
  padding: 8px;
  font-size: 20px;
  position: relative;
  p {
    font-size: 14px;
    color: #acacc4;
  }

  font-weight: 300;

  &:before {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    content: attr(data-text);
    position: absolute;
    z-index: 2;
    top: 0px;
    padding: 0px 8px;
    left: 12px;
    transform-origin: left center;
    font-size: 16px;
    color: black;
    font-weight: bold;
    transform: translate(0, -50%);
    background-color: #e5e5e5;
  }
}

.modalWrapper {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 32px;
}
