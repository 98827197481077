.partnershipFormWrapper {
  width: calc(100% - 32px);
  padding: 16px;
  background-color: white;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  transition: 1s;
}

.partnershipWrapper {
  width: calc(100% - 32px);
  padding: 16px;
  background-color: white;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  border-radius: 10px;
  display: flex;
  transition: 1s;
}

.formWrapper {
  margin-top: 64px;
}

.checkboxesWrapper {
  border: 1px solid rgb(168, 168, 168);
  background-color: rgb(241, 251, 255);
  border-radius: 10px;
  padding: 8px;
}

.title {
  font-size: 30px;
  font-weight: 700;
  text-align: center;
}

.text {
  font-size: 1rem;
  font-family: 'Poppins', sans-serif;
  color: rgb(122, 122, 122);
}

.sectionTitle {
  font-size: 24px;
  font-weight: 700;
}

.formContainer {
  width: 100%;
  margin: 16px 0px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.titleSearch {
  font-size: 28px;
  font-weight: 700;
}
