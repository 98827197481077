$primary-blue: #000041;
$primary-green: #33ce60;
$secondary-green: #00bf00;
$third-green: #0e4700;

.stepWrapper {
  font-family: "Poppins", sans-serif;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.selectedStepDisplay {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background-color: #f5f5f5;
  color: white;
  font-size: 20px;
  font-weight: 600;
  border: 2px solid #f5f5f5;
  transition: all 0.3s ease;
  z-index: 1;
  position: relative;
  &:before {
    content: "";
    position: absolute;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    background-color: $primary-blue;
    animation: appearCircle ease 1.7s;
    border: 2px solid $primary-blue;
    transition: ease 1s;
    z-index: -1;
  }
  p {
    z-index: 10;
    color: white;
  }
  .displayIcon {
    z-index: 10;
    color: white;
  }
}

.stepDisplay {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background-color: #f5f5f5;
  color: #000;
  font-size: 20px;
  font-weight: 600;
  border: 2px solid #f5f5f5;
  transition: all 0.3s ease;
  &:before {
    content: "";
    position: absolute;
    width: 0px;
    height: 0px;
    border-radius: 50%;
    background-color: $primary-blue;
    border: 2px solid $primary-blue;
    transition: ease 1s;
    z-index: -1;
  }
}

.stepTitle {
  position: absolute;
  bottom: -44px;
  font-weight: 600;
}

.stepIcon {
  position: absolute;
  top: -48px;
  font-size: 30px;
}

.line {
  width: 150px;
  height: 4px;
  background-color: #f5f5f5;
  &:before {
    content: "";
    position: absolute;
    width: 0px;
    height: 4px;
    background-color: $primary-blue;
    transition: 1s ease;
  }
}

.selectedLine {
  width: 150px;
  height: 4px;
  background-color: #f5f5f5;
  position: relative;
  &:before {
    content: "";
    position: absolute;
    width: 150px;
    height: 4px;
    background-color: $primary-blue;
    animation: appearLine ease 1s;
    transition: 1s ease;
  }
}

.multiStepWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}

@keyframes appearLine {
  0% {
    width: 0;
  }
  100% {
    width: 150px;
  }
}

@keyframes appearCircle {
  0% {
    width: 0;
    height: 0;
    opacity: 0;
  }
  50% {
    width: 0;
    height: 0;
    opacity: 0;
  }
  100% {
    height: 24px;
    width: 24px;
    opacity: 1;
  }
}
