$primary-blue: #000041;
$primary-green: #33ce60;
$secondary-green: #00bf00;
$third-green: #0e4700;

.imageDotsWrapper {
  position: absolute;
  bottom: 32px;
  left: 50%;
  transform: translate(-50%, 0);
  z-index: 10;
}

.greenDot {
  width: 16px;
  height: 16px;
  border-radius: 50%;
  z-index: 20;
  background-color: $primary-green;
  position: absolute;
  transition: 1s ease;
}

.dot {
  width: 16px;
  height: 16px;
  background-color: white;
  border-radius: 50%;
}

.selectedDot {
  width: 16px;
  height: 16px;
  background-color: white;
  border-radius: 50%;
  position: relative;
}

@keyframes pulse {
  0% {
    width: 16px;
  }
  50% {
    width: 20px;
  }
  100% {
    width: 16px;
  }
}

.greenDot.pulse {
  animation: pulse 1s cubic-bezier(0.075, 0.82, 0.165, 1);
}
