$primary-blue: #000041;
$primary-green: #33ce60;
$secondary-green: #00bf00;
$third-green: #0e4700;

.buttonWrapper {
  border-radius: 28px;
  border: 4px solid $primary-green;
  overflow: hidden;
  cursor: pointer;
  max-width: 300px;
  &:hover {
    .upper {
      background-color: white;
      color: black;
      .icon {
        transform: rotate(-360deg);
        color: black;
        transition: 1s ease;
      }
    }
    .lower {
      background-color: $primary-green;
      color: white;
    }
  }
}

.icon {
  transition: 1s;
  color: white;
}

.lower {
  padding: 8px;
  font-size: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: ease 1s;
}

.upper {
  padding: 8px;
  font-size: 20px;
  background-color: $primary-green;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: ease 1s;
}

.backgroundWrapper {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 9;
  background-color: rgba(0, 0, 0, 0.7);
  animation: appear 1s ease;
}

.deliveriesModalWrapper {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  max-width: 100%;
  height: 100%;
  max-height: 800px;
  background-color: white;
  z-index: 10;
  animation: appearModal 1s ease;
}

@keyframes appearModal {
  0% {
    transform: translate(-50%, 50%);
  }

  100% {
    transform: translate(-50%, -50%);
  }
}

@keyframes appear {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 100%;
  }
}

.headerModal {
  border-bottom: 1px solid rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 24px;
  .headerIcon {
    font-size: 28px;
    cursor: pointer;
  }
  .headerTitle {
    font-size: 20px;
  }
}

.tasksWrapper {
  height: 550px;
  margin-top: 16px;
  overflow: auto;
  p {
    font-size: 16px;
    font-weight: 600;
  }
}
.task {
  padding: 0px 16px 16px 16px;
  border-bottom: solid #d9d9d9 1px;
}

.checkbox {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #d9d9d9;
  border-radius: 15px;
  width: 40px;
  height: 40px;
  color: $primary-green;
}

.statusWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  p {
    background-color: $primary-green;
    border-radius: 30px;
    padding: 8px 20px;
    font-weight: bold;
    font-size: 20px;
    color: white;
  }
}

.linkWrapperPending {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  .linkIcon {
    font-size: 32px;
    background-color: rgb(223, 223, 56);
    color: white;
    border-radius: 50%;
    padding: 8px;
    transition: ease 1s;
  }
}

.linkWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  &:hover {
    .linkIcon {
      background-color: $primary-blue;
      padding: 4px;
      font-size: 36px;
    }
  }
  .linkIcon {
    font-size: 32px;
    background-color: $primary-green;
    color: white;
    border-radius: 50%;
    padding: 8px;
    transition: ease 1s;
  }
}

.footer {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  padding: 16px;
  border-top: 1px #d9d9d9 solid;
}
