.imageDisplayWrapper {
  height: 100%;
  width: 100%;
  border-radius: 30px 0px 0px 30px;
  position: relative;
  overflow: hidden;

  .text {
    z-index: 100;
    position: absolute;
    bottom: 64px;
    left: 50%;
    transform: translate(-50%, 0);
    color: white;
    text-align: center;
    font-family: "Poppins", sans-serif;
    font-size: 20px;
    font-weight: 600;
  }
  video {
    position: absolute;
    top: 0;
    left: 0;
    min-width: 100%;
    min-height: 100%;

    pointer-events: none;
    animation: cubic-bezier(0.075, 0.82, 0.165, 1) 2s appearAnimationFirst;
  }
  .video1 {
    position: absolute;
    top: 0;
    left: 0;
    min-width: 100%;
    min-height: 100%;
    pointer-events: none;
    animation: cubic-bezier(0.075, 0.82, 0.165, 1) 4s appearAnimation;
  }
  .video2 {
    position: absolute;
    top: 0;
    left: 0;
    min-width: 100%;
    min-height: 100%;
    pointer-events: none;
    animation: cubic-bezier(0.075, 0.82, 0.165, 1) 4s appearAnimation;
  }
}

@keyframes appearAnimation {
  0% {
    left: -3000px;
  }
  100% {
    left: 0px;
  }
}

@keyframes appearAnimationFirst {
  0% {
    left: 2000px;
  }
  100% {
    left: 0px;
  }
}
