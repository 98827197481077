.cardWrapper {
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  padding: 16px;
  border-radius: 10px;
  position: relative;
  background-color: white;
  max-height: 700px;
}

.title {
  max-width: 300px;
  font-size: 20px;
  font-weight: 600;
  color: #bdbdbd;
}

.description {
  color: black;
  font-weight: 600;
  white-space: pre-wrap; /* Preserves whitespace and wraps text */
}

.icon {
  position: absolute;
  right: 16px;
  top: 16px;
  cursor: pointer;
  height: 60px;
  width: 60px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.positionChart {
  position: absolute;
  top: 70px;
}
