$primary-blue: #000041;
$primary-green: #33ce60;
$secondary-green: #00bf00;
$third-green: #0e4700;

.buttonWrapper.clickedColor.primary {
  border: solid 1px $primary-green;
}

.primary {
  background-color: rgb(241, 251, 255);
  border: 1px solid rgb(168, 168, 168);
  border-radius: 10px;
  color: rgb(122, 122, 122);
  transition: 1s;
}

.primary.clickedColor {
  background-color: white;
}

.transparent {
  background-color: transparent;
  border-bottom: 1px solid white;
  color: white;
  transition: 1s;
  input {
    color: white;
  }
}

.transparent.clickedColor {
  border-bottom: 1px solid $primary-green;
}

.transparent-black {
  background-color: transparent;
  border-bottom: 1px solid black;
  color: black;
  transition: 1s;
  input {
    color: black;
  }
}

.transparent-black.clickedColor {
  border-bottom: 1px solid $primary-green;
}

.buttonWrapper {
  width: calc(100% - 16px);
  height: 50px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px 8px;
  transition: 1s;
  .body {
    position: absolute;
    left: 8px;
    transform: translate(0, 50%);
    bottom: 50%;
    pointer-events: none;
    font-family: 'Poppins', sans-serif;
    transition: 1s;
  }
  .body.clicked {
    bottom: 120%;
    transition: 1s;
    color: $primary-green;
  }
  input {
    height: 45px;
    border: none;
    border-radius: 10px;
    font-family: 'Poppins', sans-serif;
    width: 100%;
    background-color: transparent;
    &:active,
    &:focus {
      border: none;
      outline: none;
    }
  }
}
