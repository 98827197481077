$primary-blue: #000041;
$primary-green: #33ce60;
$secondary-green: #00bf00;
$third-green: #0e4700;

.successWrapper {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1000;
  width: 100%;
  height: 100%;
  background-color: $primary-green;
  animation: appearAnimation 3.1s ease-in-out;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  flex-direction: column;
}

.title {
  color: white;
  font-size: 36px;
  margin-top: 40px;
  min-width: 1665px;
  text-align: center;
  font-weight: 600;
}

@keyframes appearAnimation {
  0% {
    width: 0px;
    height: 0px;
    border-radius: 50%;
  }

  50% {
    width: 2000px;
    height: 2000px;
    border-radius: 50%;
  }

  75% {
    width: 2000px;
    height: 2000px;
    border-radius: 50%;
  }

  100% {
    width: 0px;
    height: 0px;
    border-radius: 50%;
  }
}

.wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  transform: scale(2);
}
.checkmark__circle {
  stroke-dasharray: 166;
  stroke-dashoffset: 166;
  stroke-width: 2;
  stroke-miterlimit: 10;
  stroke: white;
  fill: none;
  animation: stroke 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards;
}
.checkmark {
  width: 56px;
  height: 56px;
  border-radius: 50%;
  display: block;
  stroke-width: 4;
  stroke: $primary-green;
  stroke-miterlimit: 10;
  margin: 10% auto;
  box-shadow: inset 0px 0px 0px white;
  animation: fill 0.4s ease-in-out 0.4s forwards,
    scale 0.3s ease-in-out 0.9s both;
}
.checkmark__check {
  transform-origin: 50% 50%;
  stroke-dasharray: 48;
  stroke-dashoffset: 48;
  animation: stroke 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.8s forwards;
}
@keyframes stroke {
  100% {
    stroke-dashoffset: 0;
  }
}
@keyframes scale {
  0%,
  100% {
    transform: none;
  }
  50% {
    transform: scale3d(1.1, 1.1, 1);
  }
}
@keyframes fill {
  100% {
    box-shadow: inset 0px 0px 0px 30px white;
  }
}
