$primary-blue: #000041;
$primary-green: #33ce60;
$secondary-green: #00bf00;
$third-green: #0e4700;

.row {
  cursor: pointer;
  transition: 1s;
  &:hover {
    background-color: darken(white, 5%);
  }
}

.tableWrapper {
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  border-radius: 10px;
  overflow: hidden;
  thead {
    tr {
      th {
        font-family: "Poppins";
        font-size: 20px;
      }
    }
  }
}

.redirectButton {
  width: 80%;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
  color: $primary-blue;
  border-radius: 10px;
  padding: 4px 0px;
  transition: 1s;
  border: white 1px solid;
  &:hover {
    border: white 1px solid;
    color: white;
    background-color: $primary-blue;
  }
}
