$primary-blue: #000041;
$primary-green: #33ce60;
$secondary-green: #00bf00;
$third-green: #0e4700;

.datepickerWrapper {
  background-color: #f4f4f9;
  border-radius: 50px;
  padding: 12px;
  width: fit-content;
  cursor: pointer;
  transition: 0.5s ease;
  p {
    font-family: 'Poppins', sans-serif;
    font-size: 12px;
    font-weight: 700;
  }

  input[type='date'] {
    border: none;
    font-family: 'Poppins', sans-serif;
    font-size: 16px;
    font-weight: 600;
    background-color: transparent;
    &::-webkit-calendar-picker-indicator {
      display: none;
    }
  }
  &:hover {
    background-color: #e8e8f2;
  }
}

.expand {
  animation: appear 1s ease;
}

@keyframes appear {
  0% {
    height: 0px;
  }
  100% {
    height: 100%;
  }
}

.datePickerButton {
  background-color: #fdedd7;
  width: 32px;
  height: 32px;
  padding: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  color: #a34800;
  font-size: 24px;
}

.dateSelect {
  background-color: $primary-blue;
  border-radius: 50px;
  color: white;
  padding: 12px;
  font-family: 'Poppins', sans-serif;
  font-size: 16px;
  font-weight: 600;
  transition: 1s ease;
  &:hover {
    background-color: lighten($primary-blue, 10%);
  }
}

.customDatePicker input {
  border: none !important;
}

.customDatePicker .MuiInputAdornment-root {
  display: none;
}
