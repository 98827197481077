$primary-blue: #000041;
$primary-green: #33ce60;
$secondary-green: #00bf00;
$third-green: #0e4700;

p {
  font-family: "Poppins", sans-serif;
}

.title {
  font-weight: 500;
  font-size: 36px;
}

.body {
  font-weight: 300;
  font-size: 20px;
  text-align: center;
}

.checkBox {
  font-size: 8px;
  font-weight: 400;
}

.forgotPassword {
  font-size: 16px;
  font-weight: 700;
  cursor: pointer;
}

.line {
  left: 0px;
  width: 46px;
  height: 1px;
  border-top: 1px solid $primary-blue;
}

.lineText {
  font-size: 20px;
}
