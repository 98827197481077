$primary-blue: #000041;
$primary-green: #33ce60;
$secondary-green: #00bf00;
$third-green: #0e4700;

.buttonWrapper {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  color: white;
  width: 100%;
  font-size: 20px;
  font-family: 'Poppins', sans-serif;
  cursor: pointer;
}

.primary {
  transition: 1s ease-in-out;
  border: 1px solid $primary-green;
  background-color: $primary-green;
  border-radius: 10px;
  height: 50px;
  &:hover {
    background-color: transparent;
    border: 1px solid $primary-green;
    color: $primary-green;
    font-weight: 800;
  }
}

.secondary {
  transition: 1s ease-in-out;
  background-color: $primary-blue;
  border: 1px solid $primary-blue;
  border-radius: 10px;
  height: 50px;
  &:hover {
    background-color: transparent;
    border: 1px solid $primary-blue;
    color: $primary-blue;
    font-weight: 800;
  }
}

.third {
  background-color: transparent;
  border: 1px solid white;
  transition: 1s;
  border-radius: 10px;
  height: 50px;
  &:hover {
    border: 1px solid white;
    color: $primary-blue;
    background-color: white;
    font-weight: 800;
  }
}

.blue-first {
  color: white;
  background-color: #1756c5;
  transition: 0.5s ease;
  height: 30px !important;
  padding: 8px;
  &:hover {
    background-color: #386bc4;
  }
}

.blue-second {
  color: #1756c5;
  background-color: #ebf1f9;
  transition: 0.5s ease;
  height: 30px !important;
  padding: 8px;
  &:hover {
    background-color: #d9e3f5;
  }
}
.fourth {
  background-color: transparent;
  border: 1px solid $primary-blue;
  background-color: $primary-blue;
  color: white;
  transition: 1s;
  border-radius: 30px;
  height: 48px;
  &:hover {
    border: 1px solid white;
    color: $primary-blue;
    background-color: white;
    font-weight: 800;
  }
}

.disabled {
  background-color: #f5f5f5;
  border: 1px solid #f5f5f5;
  color: #9e9e9e;
  cursor: not-allowed;
  &:hover {
    background-color: #f5f5f5;
    border: 1px solid #f5f5f5;
    color: #9e9e9e;
  }
}
