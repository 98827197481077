.tableWrapper {
  border: 1px solid #d9d9d9;
}

.tableRow {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  padding: 10px;
  border-bottom: 1px solid #d9d9d9;
  font-family: "Poppins", sans-serif;
}

.tableCellLow {
  color: #a5a5a5;
  font-size: 14px;
}
