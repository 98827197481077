$primary-blue: #000041;
$primary-green: #33ce60;
$secondary-green: #00bf00;
$third-green: #0e4700;

.profileSummary {
  background-color: #007bff;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  font-size: 20px;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  color: white;
  padding: 32px 0px;
}

.profileWrapper {
  box-shadow: 1px 1px 24px 0px rgba(0, 0, 0, 0.75);
  -webkit-box-shadow: 1px 1px 24px 0px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 1px 1px 24px 0px rgba(0, 0, 0, 0.75);
  border-radius: 10px;
  overflow: hidden;
  color: black;
  font-size: 16px;
  font-weight: 400;
  background-color: white;
}

.dataTableWrapper {
  margin: 32px 0px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.dataTableSizer {
  width: 80%;
}

.sessionWrapper {
  font-family: "Poppins", sans-serif;
  font-size: 40px;
  font-weight: bold;
  color: white;
}

.sectionTitle {
  font-size: 24px;
  font-weight: 600;
}

.title {
  font-size: 20px;
  font-weight: 600;
}

.text {
  font-weight: 300;
}

.badge {
  padding: 4px 8px;
  background-color: $primary-green;
  border-radius: 30px;
  font-size: 10px;
  color: white;
  font-weight: 600;
}
