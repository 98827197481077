$primary-blue: #000041;
$primary-green: #33ce60;
$secondary-green: #00bf00;
$third-green: #0e4700;

.registerWrapper {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1000;
  width: 100%;
  height: 100%;
  background-color: $primary-blue;
  animation: appearAnimation 1s ease-in-out;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  flex-direction: column;
  color: white;
  font-family: "Poppins", sans-serif;
  animation: 1s appearAnimation ease-in-out;
}

.title {
  font-family: "Poppins", sans-serif;
  font-size: 56px;
  color: white;
  font-weight: 500;
  text-align: center;
}

@keyframes appearAnimation {
  0% {
    width: 0px;
    height: 0px;
    border-radius: 50%;
  }

  100% {
    width: 2000px;
    height: 2000px;
    border-radius: 50%;
  }
}
