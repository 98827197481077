$primary-blue: #000041;
$primary-green: #33ce60;
$secondary-green: #00bf00;
$third-green: #0e4700;

.menuSideBarWrapper {
  animation: 1s ease-in-out appear;
  width: fit-content;
  height: 100%;
  background-color: $primary-blue;
  color: white;
  font-family: "Poppins", sans-serif;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-weight: 500;
  border-radius: 20px;
  z-index: 3;
  .menuItem {
    padding: 8px 24px;
    border-radius: 10px;
    position: relative;
    z-index: 2;
    display: flex;
    align-items: center;
    cursor: pointer;
  }

  .menuTitle {
    font-size: 14px;
    font-weight: 300;
  }
}

.selected::before,
.notSelected::before {
  content: "";
  position: absolute;
  left: 50%;
  transform: translate(-50%, 0);
  top: 0;
  width: 100%;
  height: 100%;
  border-radius: 10px;
  transition: 1s;
  pointer-events: none;
  z-index: -1;
}

.selected::before {
  background-color: $primary-green;
}

.notSelected::before {
  background-color: $primary-green;
  width: 0%;
}

.line {
  border-bottom: 2px white solid;
  width: 100%;
}

.headerTitle {
  font-size: 20px;
}

@keyframes appear {
  0% {
    transform: translateY(-1000px);
  }
  100% {
    transform: translateY(0px);
  }
}
